import React from "react"
import SinglePageHeader from "../../components/SinglePageLayout"

export default function Csr() {
  return (
    <SinglePageHeader
      title="Letter to ICH Bursary Recipients"
      bg="/assets/img/csr-banner.jpg"
    >
      <article className="ich-post ich-container">
        <h3 className="post-title">Letter to ICH Bursary Recipients</h3>
        <br />
        <p>Dear All,</p>
        <p>
          Over the last 11 years since ICH established its first bursary at NUS,
          we have supported more than 100 students and has since expanded the
          ICH Group bursaries to 2 more universities, initiated support to
          technical colleges and also set up ICH open bursaries for recipients
          under 15 years old. As one of the recipients, I think it can be helpful
          to you to understand the spirit behind the establishment of ICH
          bursaries.
        </p>
        <p>
          There are many forms one can contribute to the society. At the business
          and commercial levels, ICH's commitment is to continue to fund
          companies and projects and individuals that aspire to develop
          businesses that create high quality employment and at the same time
          build meaningful products and services while generating income to
          grow. ICH shuns projects that promote vice-like indulgence and projects that are
          led by individuals or companies that have questionable characters
          and/or intent.
        </p>
        <p>
          On the social front, we believe education and affordable healthcare
          are fundamental equalizers that every responsible and able leader
          within a society should try their best to provide support. There is no
          equal starting line in life. In fact, this unequal starting point can
          be an equalizer, by providing the hunger and aspirations needed to
          increase every individual's social mobility.
        </p>
        <p>
          Over the years I have read thank you letters from many of you and what
          motivates me and keep me excited about humanity is that despite all
          the difficulties we face, we are able to find our way to excel in
          life. To many of us who have been dealt tough cards in life, always
          remember that this is not a handicap nor unfairness that should
          discourage you. Every encounter, every experience should be received
          with your strength and dignity. And like many successful individuals,
          you will grow to understand that without these setbacks or tough
          experiences, you would not have achieved what you have achieved so
          far.
        </p>
        <p>
          It is my wish that all of you find this letter useful especially when
          you are in self-doubt or handling tough situations that can often lead
          to questioning of your original intent. At the very least, you know I
          am here, always, to be your peer in your pursuit. Remember we are not
          just giving you a bursary. We want to play an active role in building
          characters that are required of every outstanding individual. Please
          reach out to me or ICH directly if you ever feel the desire to, for a
          guidance, a cup of coffee or just to tell us who you are and what you
          want to do to make a difference to the society you are living in.
        </p>
        <br />
        <p>Sincerely, Danny Toe</p>
        <p>Founder, Chairman &amp; CEO ICH Group</p>
      </article>
    </SinglePageHeader>
  )
}
